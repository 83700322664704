import React from 'react';
import { useState, useEffect } from 'react';
import "./AboutYou.css";
import PartTimePeriod from "./PartTimePeriod.js"

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import Check from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddBoxOutlined';
import RemoveCircleIcon from '@mui/icons-material/Delete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import NativeSelect from '@mui/material/NativeSelect';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from 'react-router-dom';
import moment from 'moment';



// These are the names of the bullet stepper used to indicate what stage of the inputs required the user is at
const steps = ['Select scheme', 'Eligibility check', 'Your earnings', 'Part-time service'];

// Color theme throughout 
const colorTheme = "#af292e";
export { colorTheme };

const themeGAD = createTheme({
    palette: {
      primary: {
        light: '#af292e',
        main: '#af292e',
        dark: '#af292e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  export {themeGAD};


// Style of the lines that connect the steppers
const StyledConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 12,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: colorTheme,
          },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: colorTheme,
          },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  // Style of the steppers 
  const StyledIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: colorTheme,
    }),
    '& .StyledIcon-completedIcon': {
      color: colorTheme,
      zIndex: 1,
      fontSize: 18,
    },
    '& .StyledIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  // Function that renders a stepper
  function StyledIcon(props) {
    const { active, completed, className } = props;
    return (
      <StyledIconRoot ownerState={{ active }} className={className}>
        {completed ? (
        <Check className="StyledIcon-completedIcon" />
        ) : (
          <div className="StyledIcon-circle" />
        )}
      </StyledIconRoot>
    );
  }
  StyledIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: colorTheme,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorTheme,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorTheme,
        },
        '&:hover fieldset': {
          borderColor: colorTheme,
        },
        '&.Mui-focused fieldset': {
          borderColor: colorTheme,
        },
      },
  });

  export {StyledTextField};

function AboutYou( { addressAPI, callbackEmployer, callbackScheme, callbackRegion, callbackDateOfBirth, callbackDateJoinedScheme, 
    callbackABS, callbackSalaryStartYear, callbackSalaries, callbackPartTime, callbackRankAtRetirement, callbackIsMemberOfMet, callbackHasUserInputData,
    callbackOptionsToRun, callbackPrevSchemeName, callbackCurrSchemeName,
    callbackAgeSPA, callbackAgeVRA, callbackDeferredAge, callbackRetirementDates, callbackRetirementAges, callbackCareAges,
    callbackCareAgesMinimum, callbackCareAgesMaximum, callbackDateLeftSchemeArray, callbackLeftScheme, callbackMaxCommutationPrevScheme,
    callbackMaxCommutationCurrScheme, callbackCommutationFactorPrevScheme, callbackCommutationFactorCurrScheme,
    callbackChosenCommutationProportionPrevScheme, callbackChosenCommutationProportionCurrScheme,
    callbackAnnualPensionPrevScheme, callbackAnnualPensionCurrScheme, callbackFinalSalary, callbackCpi, callbackInfographicShow,
    callbackInfographicPrevSchemePension, callbackInfographicCurrSchemePensionInPayment, callbackInfographicCurrSchemePensionLaterPayment,
    callbackInfographicPrevSchemePensionNormalised, callbackInfographicCurrSchemePensionInPaymentNormalised,
    callbackInfographicCurrSchemePensionLaterPaymentNormalised, callbackInfographicLumpSum2015, callbackInfographicLumpSum1987,
    callbackInfographicIntroduction, callbackInfographicCareIntroduction, callbackInfographicFinalSalaryCommutation,
    callbackInfographicCareCommutation, callbackTitles, callbackMinDeferredAge, callbackMaxDeferredAge, callbackRealAge,
    callbackAnnualPensionNominal, callbackAnnualPensionReal, callbackAnnualPensionProportional,
    callbackLumpSumNominal, callbackLumpSumReal, callbackLumpSumProportional, callbackCurrentAge,
    callbackLumpSumComparisonChart,
    callbackInfographicPrevSchemePensionEqualised, callbackInfographicCurrSchemePensionInPaymentEqualised, callbackInfographicCurrSchemePensionLaterPaymentEqualised,
    callbackInfographicPrevSchemePensionNormalisedEqualised, callbackInfographicCurrSchemePensionInPaymentNormalisedEqualised,
    callbackInfographicCurrSchemePensionLaterPaymentNormalisedEqualised, callbackInfographicLumpSum2015Equalised, callbackInfographicLumpSum1987Equalised,
    callbackInfographicCareCommutationEqualised,
    callbackInfographicCommutationProportion1987, callbackInfographicCommutationProportion2015,
    callbackInfographicAutoLumpSum} ) 
{
    const [activeStep, setActiveStep] = useState(0);

    const [employer, setEmployer] = useState("NHS");
    const [scheme, setScheme] = useState("");
    const [region, setRegion] = useState("Northern Ireland");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dateJoinedScheme, setDateJoinedScheme] = useState(null);
    const [dateLeftScheme, setDateLeftScheme] = useState(null);
    const [isEligible, setIsEligible] = useState(null);
    const [dateOfBirthValid, setDateOfBirthValid] = useState()
    const [abs, setAbs] = useState(0);//Note - placeholder for an ABS input
    const [salaryStartYear, setSalaryStartYear] = useState(2015); //Note - placeholder for an ABS input
    const [salaries, setSalaries] = useState([]);
    const [partTime, setPartTime] = useState([{from: null, to: null, proportion: null},]);
    const [fillPartTimeFlag, setFillPartTimeFlag] = useState(false);
    const [partTimeOverlapWarning, setPartTimeOverlapWarning] = useState(false);
    const [isRetired, setIsRetired] = useState("waiting");
    const [hasLeftScheme, setHasLeftScheme] = useState("waiting");
    const [isDateJoinedValid, setIsDateJoinedValid] = useState(false);
    const [isMemberOfMet, setIsMemberOfMet] = useState(false);
    const [rankAtRetirement, setRankAtRetirement] = useState("Constable");

    const [status, setStatus] = useState({
        special: false,
        mho: false
    });

    const [statusName, setStatusName] = useState("Standard_Officer")

    const [loading, setLoading] = useState(false);

    const [currSalary, setCurrSalary] = useState("")
    const [prevSalary, setPrevSalary] = useState("")

    const [validPartTimeFlag, setValidPartTimeFlag] = useState(true);

    // For Date() months are zero indexed
    const schemeDateStatus = new Date(1995, 3, 1)
    const schemeDate2006 = new Date(2008, 3, 1)
    const schemeDate2012 = new Date(2012, 3, 1)
    const schemeDate2015 = new Date(2015, 3, 1)

    //console.log({'activestep': activeStep,'scheme':scheme})


    useEffect(() => {
        window.scrollTo(0, 0);
      }, [activeStep]);

    useEffect(() => {
        handleStatusName();
      }, [scheme, status]);    
  
    const handleStatusChange = (event) => {
        setStatus({
          ...status,
          [event.target.name]: event.target.checked,
        });
      };


    //console.log(status)

    const {special, mho} = status

    const handleStatusName = () => {
        switch(scheme) {
            case '1995':
                if (status['special'] && status['mho']) {
                    setStatusName("MHO_Special_Class")
                } else if (status['special'] && !status['mho']) {
                    setStatusName("Special_Class")
                } else if (!status['special'] && status['mho']) {
                    setStatusName("MHO")
                } else {
                    setStatusName("Standard_Officer")
                }
                break;
            case '2008':
                setStatusName("Officer")
                break;
            default:
                setStatusName("Standard_Officer")
        }
    }
    
    //console.log(statusName)
    
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 3){
          if (partTime[partTime.length-1]['from'] === null || partTime[partTime.length-1]['to'] === null || partTime[partTime.length-1]['proportion'] === '' || partTime[partTime.length-1]['proportion'] === null ) {
            partTime.pop()
          }
      };
    };

    const handleNextEligible = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        var upperDate
        if (hasLeftScheme === false) {
            upperDate = new Date()
        } else {
            upperDate = dateLeftScheme
        }
        const april2015 = schemeDate2015
        const diffTime = Math.abs(upperDate - april2015) // In milliseconds
        const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365))

        var tempSalaries = []
        for (let i = 0; i < diffYears; i++) {
            tempSalaries.push(0)
        }
        setSalaries(tempSalaries)
    }

    const handleNextSalary = () => {
        let tempSalaries = salaries
        if (prevSalary !== null) {
            tempSalaries[0] = prevSalary
        }
        tempSalaries[tempSalaries.length - 1] = currSalary
        setSalaries(tempSalaries)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  
    const handleBack = () => {
      let tempSalaries = salaries
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      switch(activeStep) {
        case 1:
            setScheme("")
            setDateOfBirth(null)
            setDateJoinedScheme(null)
            setDateLeftScheme(null)
            setIsRetired("waiting")
            setHasLeftScheme("waiting")

            break
        case 2:
            setDateOfBirth(null)
            setDateJoinedScheme(null)
            setDateLeftScheme(null)
            setIsRetired("waiting")
            setHasLeftScheme("waiting")
            for (let i = 0; i < tempSalaries.length -1; i++) {
                tempSalaries[i] = 0 
            }
            setSalaries(tempSalaries)
            setCurrSalary("")
            setPrevSalary("")
            break
        case 3:
            for (let i = 0; i < tempSalaries.length -1; i++) {
                tempSalaries[i] = 0 
            }
            setSalaries(tempSalaries)
            setCurrSalary("")
            setPrevSalary("")

            setPartTime([{from: null, to: null, proportion: null},])
            setFillPartTimeFlag(false)
            setValidPartTimeFlag(true)
            break
        case 4:
            setPartTime([{from: null, to: null, proportion: null},])
            setFillPartTimeFlag(false)
            setValidPartTimeFlag(true)
            setIsMemberOfMet(false);
            break
        case 5:

            setIsMemberOfMet(false);

            break;
        default:

      }
    };

    const history = useHistory();
  
    const handleSubmit = async () => {

        setLoading(true)

        const data = {
            "employer" : employer,
            "scheme" : scheme,
            "region" : region,
            "dateOfBirth" : dateOfBirth,
            "dateJoinedScheme" : dateJoinedScheme,
            "dateLeftScheme" : dateLeftScheme,
            "ABS" : abs,
            "salaryStartYear" : salaryStartYear,
            "salaries" : salaries,
            "partTime" : partTime,
            "rankAtRetirement" : rankAtRetirement,
            "isMemberOfMet" : isMemberOfMet,
            "statusName": statusName
        }

        //console.log(data)

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        console.log(JSON.stringify(data))

        let address = addressAPI + "api/setup"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            //console.log(data)
            callbackEmployer(employer)
            callbackScheme(scheme)
            callbackRegion(region)
            callbackDateOfBirth(dateOfBirth)
            callbackDateJoinedScheme(dateJoinedScheme)
            callbackABS(abs)
            callbackSalaryStartYear(salaryStartYear)
            callbackSalaries(salaries)
            callbackPartTime(partTime)
            callbackRankAtRetirement(rankAtRetirement)
            callbackIsMemberOfMet(isMemberOfMet)
            callbackPrevSchemeName(data['constants']['prevSchemeName'])
            callbackCurrSchemeName(data['constants']['currSchemeName'])
            callbackAgeSPA(data['constants']['SPA'])
            callbackAgeVRA(data['constants']['VRA'])
            callbackDeferredAge(data['scenarioParameters']['deferredAge'])
            callbackCurrentAge(data['constants']['currentAge'])
            callbackRetirementDates(data['scenarioParameters']['retirementDate'])
            callbackRetirementAges(data['scenarioParameters']['retirementAge'])
            callbackCareAges(data['scenarioParameters']['careAge'])
            callbackCareAgesMinimum(data['scenarioParameters']['careAgeMinimum'])
            callbackCareAgesMaximum(data['scenarioParameters']['careAgeMaximum'])
            callbackDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            callbackLeftScheme(data['scenarioParameters']['leftScheme'])
            callbackMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            callbackMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])
            callbackCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            callbackCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            callbackChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            callbackChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            callbackAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            callbackAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            callbackFinalSalary(data['scenarioParameters']['finalSalary'])
            callbackCpi(data['cpi'])
            callbackInfographicPrevSchemePension(data['infographicData']['1987 Pension'])
            callbackInfographicCurrSchemePensionInPayment(data['infographicData']['2015 Pension - In Payment'])
            callbackInfographicCurrSchemePensionLaterPayment(data['infographicData']['2015 Pension - Later Payment'])
            callbackInfographicPrevSchemePensionNormalised(data['infographicData']['1987 Pension normalised'])
            callbackInfographicCurrSchemePensionInPaymentNormalised(data['infographicData']['2015 Pension - In Payment normalised'])
            callbackInfographicCurrSchemePensionLaterPaymentNormalised(data['infographicData']['2015 Pension - Later Payment normalised'])
            callbackInfographicLumpSum2015(data['infographicData']['lumpSum2015'])
            callbackInfographicLumpSum1987(data['infographicData']['lumpSum1987'])
            callbackInfographicPrevSchemePensionEqualised(data['infographicData']['1987 Pension Equalised'])
            callbackInfographicCurrSchemePensionInPaymentEqualised(data['infographicData']['2015 Pension - In Payment Equalised'])
            callbackInfographicCurrSchemePensionLaterPaymentEqualised(data['infographicData']['2015 Pension - Later Payment Equalised'])
            callbackInfographicPrevSchemePensionNormalisedEqualised(data['infographicData']['1987 Pension normalised Equalised'])
            callbackInfographicCurrSchemePensionInPaymentNormalisedEqualised(data['infographicData']['2015 Pension - In Payment normalised Equalised'])
            callbackInfographicCurrSchemePensionLaterPaymentNormalisedEqualised(data['infographicData']['2015 Pension - Later Payment normalised Equalised'])
            callbackInfographicLumpSum2015Equalised(data['infographicData']['lumpSum2015 Equalised'])
            callbackInfographicLumpSum1987Equalised(data['infographicData']['lumpSum1987 Equalised'])
            callbackInfographicIntroduction(data['infographicData']['introduction'])
            callbackInfographicCareIntroduction(data['infographicData']['careIntroduction'])
            callbackInfographicFinalSalaryCommutation(data['infographicData']['finalSalaryCommutation'])
            callbackInfographicCareCommutation(data['infographicData']['careCommutation'])
            callbackInfographicCareCommutationEqualised(data['infographicData']['careCommutation Equalised'])
            callbackTitles(data['titles'])
            callbackMinDeferredAge(data['minDeferredAge'])
            callbackMaxDeferredAge(data['maxDeferredAge'])
            callbackAnnualPensionNominal(data['annualPension_nominal'])
            callbackAnnualPensionReal(data['annualPension_real'])
            callbackAnnualPensionProportional(data['annualPension_proportion'])
            callbackLumpSumNominal(data['lumpSum_nominal'])
            callbackLumpSumReal(data['lumpSum_real'])
            callbackLumpSumProportional(data['lumpSum_proportion'])
            callbackLumpSumComparisonChart(data['lumpSum_comparison'])
            callbackInfographicAutoLumpSum(data['infographicData']['auto_lumpsum'])
        
            callbackHasUserInputData(true)

            callbackMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])

            if (data['scenarioParameters']['leftScheme'].length ===1 && data['scenarioParameters']['leftScheme'][0] === true ) {
                callbackInfographicShow(false)
            } else{
                callbackInfographicShow(true)
            }

            data['infographicData']['auto_lumpsum'] && callbackInfographicCommutationProportion1987(1)
            data['infographicData']['auto_lumpsum'] && callbackInfographicCommutationProportion2015(0)
            
            setLoading(false)
            history.push("your summary")

        }).catch(function (error) {
            console.log(error);
        });


    };

    const handleDateChange = (newDate, setDate, flag) => {
        const minDate = schemeDate2012
        const maxDate = schemeDate2015
        //console.log(newDate);
        //console.log(flag)
        setDate(newDate)
        if (flag === "joined") {
            if (moment(newDate) - moment(dateOfBirth) - 504921600000 >= 0) {
                setIsDateJoinedValid(true)
            } else {
                setIsDateJoinedValid(false)
            }
            if (newDate < minDate && (newDate > dateOfBirth) && (dateLeftScheme >= maxDate || dateLeftScheme === null) && (isRetired !== true )) {
                setIsEligible(true)
            } else {
                setIsEligible(false)
            }
        } 
        
        if (flag === "left" && newDate !== null) {
            //console.log({'period from 16 - left': moment(dateJoinedScheme) - moment(dateOfBirth) - 504921600000})
            if (moment(dateJoinedScheme) - moment(dateOfBirth) - 504921600000 >= 0) {
                setIsDateJoinedValid(true)
            } else {
                setIsDateJoinedValid(false)
            }
            if (dateJoinedScheme < minDate && (dateJoinedScheme > dateOfBirth) && (newDate >= maxDate || dateLeftScheme === null) && (isRetired !== true )) {
                setIsEligible(true)
            } else {
                setIsEligible(false)
            }
        }

        if (flag === "birth" && newDate !== null) {
            if (newDate['_isValid']) {
                //console.log({'period from 16 - birth': moment(dateJoinedScheme) - moment(newDate) - 504921600000})
                if (moment(dateJoinedScheme) - moment(newDate) - 504921600000 >= 0) {
                    setIsDateJoinedValid(true)
                } else {
                    setIsDateJoinedValid(false)
                }                
                if (newDate < moment(new Date() - yearsToMilliseconds(68) ) ) { // 68 years in milliseconds
                    setDateOfBirthValid(false)
                    setIsEligible(false)
                } else {
                    setDateOfBirthValid(true)
                    if (dateJoinedScheme < minDate && (dateJoinedScheme > dateOfBirth) && (dateLeftScheme >= maxDate || dateLeftScheme === null) && (isRetired !== true )) {
                        setIsEligible(true)
                    } else {
                        setIsEligible(false)
                    }
                }

            }
        }

        


    };

    const handleSalaryInput = (event, setValue) => {
        if(event.target.value === "") {
            setValue("");
        } else if ( ! isNaN(Number(event.target.value)) ) {
            if (event.target.value < 0) {
                setValue(0)
            } else {
                setValue(Number(event.target.value))
            }
        }        
    }

    const handleAddPartTimeField = (index) => {

        if (partTime[index]['from'] === null || partTime[index]['to'] === null || partTime[index]['proportion'] === null) {
            setFillPartTimeFlag(true)
        } else {
            setPartTime([...partTime, {from: null, to: null, proportion:null}])
            setFillPartTimeFlag(false)
        }
    };

    const handleHasNotLeftScheme = () =>{
        setHasLeftScheme(false)
        setDateLeftScheme(null)
    }

    const handleRankAtRetirementChange = (event) => {
        setRankAtRetirement(event.target.value)
    }

    const yearsToMilliseconds = (years) => {
        return years * 31556952000
    }

    // console.log({
    //     'scheme':scheme,
    //     'isEligible':isEligible,
    //     'dateJoinedScheme':dateJoinedScheme,
    //     'isRetired':isRetired,
    //     'hasLeftScheme':hasLeftScheme,
    //     'schemeDate2006':schemeDate2006,
    //     'isDateJoinedValid':isDateJoinedValid,
    //     'partTime':partTime,
    //     'fillPartTimeFlag':fillPartTimeFlag,
    //     'validPartTimeFlag':validPartTimeFlag
    // })

    return (
            <div className="aboutyou__body">
                {loading === true ? (
                    <div id="loading"/>
                ) : (
                    null
                )}
                <div className="aboutyou__stepper__container">
                    <Box sx={{ width: '100%' }}>
                        <div className="stepper__topbar">
                        <Stepper activeStep={activeStep} alternativeLabel connector={<StyledConnector />}>
                            {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                <StepLabel StepIconComponent={StyledIcon} {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        </div>


                        {activeStep === 0 ? (
                            <div className="radio__container">
                            <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>Which legacy scheme were you a member of prior to 1st April 2015?</Typography>     
                            {employer === 'NHS' ? (
                            <div className="radiobuttons__scheme">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="employer"
                                        name="radio-buttons-group">
                                        <FormControlLabel 
                                        value={scheme === "1995" ? "1995" : ""} 

                                        checked={scheme === "1995"}
                                        control={<Radio style={{color:colorTheme}} />} 
                                        label="1995"
                                        onClick={() => setScheme('1995')} />
                                        <FormControlLabel 
                                        value={scheme === "2008" ? "2008" : ""} 
                                        checked={scheme === "2008"}
                                        control={<Radio style={{color:colorTheme}} />} 
                                        label="2008"
                                        onClick={() => {setScheme('2008'); 
                                                        setStatus({
                                                            special: false,
                                                            mho: false
                                                        });
                                        }} />
                                    </RadioGroup>                                
                                </FormControl>
                            </div>
                            ) : (
                                null
                            )}

                            {scheme === '1995' && (
                                <div>
                                    <Typography sx={{ mt: 2, mb: 1 }}>Select any special or MHO statuses you have before clicking Next</Typography> 
                                    <FormControl  component="fieldset" variant="standard">
                                        <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={special} onChange={handleStatusChange} name="special" sx={{
                                                color: colorTheme,
                                                '&.Mui-checked': {
                                                  color: colorTheme,
                                                },}}/>
                                            }
                                            label="Special Class"
                                        />
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={mho} onChange={handleStatusChange} name="mho" sx={{
                                                color: colorTheme,
                                                '&.Mui-checked': {
                                                  color: colorTheme,
                                                },}}/>
                                            }
                                            label="Mental Health Officer"
                                        />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                                
                            )}

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button variant="outlined"
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                >
                                Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {scheme === '' ? (
                                    <Button variant="outlined"
                                    style={{color:"rgb(198,198,198)"}}
                                    disabled
                                    onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                ) : (
                                    <Button variant="outlined" 
                                    style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  
                                    onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>  
                                )}
                            </Box>
                            </React.Fragment>
                        </div>



                        ) : activeStep === 1 ? (
                            
                            <div className="radio__container">
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>Are you eligible for remedy?</Typography>
                                    <Typography sx={{ mt: 2, mb: 1 }}>To be eligible for McCloud Remedy you must have joined the pension scheme before 1st April 2012 and remained a member until at least 31st March 2015
                                    </Typography>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <ThemeProvider theme={themeGAD}>
                                            <div className="calendar__container">
                                                <DesktopDatePicker 
                                                label="Date of birth"
                                                inputFormat="DD/MM/yyyy"
                                                value={dateOfBirth}
                                                minDate={moment(new Date() - yearsToMilliseconds(68) - 43200000)}
                                                maxDate={moment(new Date() - yearsToMilliseconds(16) + 43200000)}
                                                onChange={(date) => handleDateChange(date, setDateOfBirth, "birth")}
                                                renderInput={(params) => <TextField {...params}/>}
                                                />
                                                {dateOfBirthValid === false && (
                                                    <Typography sx={{ mt: 2, mb: 1 }}>The maximum age for this calculator is 68
                                                    </Typography>
                                                )}
                                            </div>
                                            {dateOfBirth !== null ? (
                                                dateOfBirth['_isValid'] && dateOfBirthValid === true ? (
                                                    <div className="calendar__container">   
                                                    <DesktopDatePicker
                                                    label="Date joined scheme"
                                                    inputFormat="DD/MM/yyyy"
                                                    value={dateJoinedScheme}
                                                    minDate={moment(dateOfBirth + yearsToMilliseconds(16) - 43200000)}
                                                    maxDate={moment(new Date())}
                                                    onChange={(date) => handleDateChange(date, setDateJoinedScheme, "joined")}
                                                    renderInput={(params) => <TextField {...params}/>}
                                                    />
                                                </div>
                                                ) : (
                                                    <div className="calendar__container">   
                                                    <DesktopDatePicker
                                                    label="Date joined scheme"
                                                    inputFormat="DD/MM/yyyy"
                                                    value={dateJoinedScheme}
                                                    minDate={moment(dateOfBirth + yearsToMilliseconds(16) - 43200000)}
                                                    maxDate={moment(new Date())}
                                                    onChange={(date) => handleDateChange(date, setDateJoinedScheme, "joined")}
                                                    renderInput={(params) => <TextField {...params}/>}
                                                    disabled
                                                    />
                                                </div>  
                                                )

                                            ) : (
                                            <div className="calendar__container">   
                                                <DesktopDatePicker
                                                label="Date joined scheme"
                                                inputFormat="DD/MM/yyyy"
                                                value={dateJoinedScheme}
                                                minDate={moment(dateOfBirth + yearsToMilliseconds(16) - 43200000)}
                                                maxDate={moment(new Date())}
                                                onChange={(date) => handleDateChange(date, setDateJoinedScheme, "joined")}
                                                renderInput={(params) => <TextField {...params}/>}
                                                disabled
                                                />
                                            </div>        
                                            )}
   
                                            {moment(dateJoinedScheme).valueOf() - moment(dateOfBirth).valueOf() < yearsToMilliseconds(16) && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>You must have been at least 16 years old when you joined the scheme.</Typography>
                                            )}

                                            <Typography sx={{ mt: 2, mb: 1 }}>Are you currently in receipt of your HSC pension, through full or partial retirement?</Typography>
                                            <div className="radiobuttons__region">
                                                <FormControl component="fieldset">
                                                    <RadioGroup row
                                                        aria-label="retired"
                                                        name="radio-buttons-group">
                                                        <FormControlLabel 
                                                        value="yes" 
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="Yes"
                                                        onClick={() => setIsRetired(true)} />
                                                        <FormControlLabel 
                                                        value="no" 
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="No"
                                                        onClick={() => setIsRetired(false)} />
                                                    </RadioGroup>
                                                    {!isRetired ? (
                                                    <div>
                                                    <Typography sx={{ mt: 2, mb: 1 }}>Have you opted out of the pension scheme or have you left service?</Typography>
                                                    <RadioGroup row
                                                        aria-label="leftScheme"
                                                        name="radio-buttons-group">
                                                        <FormControlLabel 
                                                        value="yes" 
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="Yes"
                                                        onClick={() => setHasLeftScheme(true)} />
                                                        <FormControlLabel 
                                                        value="no" 
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="No"
                                                        onClick={() => handleHasNotLeftScheme() }/>
                                                    </RadioGroup>
                                                    </div>
                                                    ) : isRetired === true ? (
                                                        <p>As you have already retired your pension administrator will be in touch regarding McCloud Remedy if you are impacted.</p>
                                                    ) : isRetired === "waiting" && (
                                                        null
                                                    )}
                                                    {hasLeftScheme === true && isRetired === false ? (
                                                    <div className="calendar__container">
                                                        <Typography sx={{ mt: 2, mb: 1 }} style={{marginTop:"0px"}}>When did you leave the pension scheme?</Typography>
                                                        <DesktopDatePicker
                                                        label="Date left scheme"
                                                        inputFormat="DD/MM/yyyy"
                                                        value={dateLeftScheme}
                                                        minDate={moment(dateJoinedScheme)}
                                                        onChange={(date) => handleDateChange(date, setDateLeftScheme, "left")}
                                                        renderInput={(params) => <TextField {...params}/>}
                                                        />
                                                    </div> 
                                                    ) : hasLeftScheme === false ? (
                                                        null
                                                    ) : hasLeftScheme === "waiting" && (
                                                        null
                                                    )}
                                                </FormControl>
                                            </div>
                                        
                                        {/* remove this block when 2006 scheme option is added into tool */}

                                        {/* {dateJoinedScheme > schemeDate2006 && dateJoinedScheme < schemeDate2015 && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>This calculator currently only allows for members with 1987 scheme benefits and will not work for members who initially joined the 2006 scheme</Typography>
                                        )} */}

                                        {/* add this block back in once 2006 scheme option added into tool */}
                                        {dateJoinedScheme !== null && scheme === "1995" && dateJoinedScheme >= schemeDate2006 && dateJoinedScheme < schemeDate2015 && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>We would expect members to have joined on or after 1 April 2008 to be members of the 2008 Section. You have told us you are in the 1995 Section.</Typography>
                                        )}
                                        {dateJoinedScheme !== null && scheme === "1995" && (status.special || status.mho) &&  dateJoinedScheme >= schemeDateStatus && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>We would not expect members who have joined on or after 1 April 1995 to have either Special Class or MHO status. You have told us you have {status.special && status.mho ? "both Special Class and MHO" : status.special ? "Special Class" : "MHO"} status.</Typography>
                                        )}                                        
                                        {dateJoinedScheme !== null && scheme === "2008" && dateJoinedScheme < schemeDate2006 && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>We would expect members to have joined before 1 April 2008 to be members of the 1995 Section. You have told us you are in the 2008 Section.</Typography>
                                        )}  

                                        
                                        {dateJoinedScheme >= schemeDate2012 && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>To be eligible for McCloud Remedy you must have joined the pension scheme before 1st April 2012.</Typography>
                                        )}                                 

                                        {((dateOfBirth !== null && dateJoinedScheme !== null && isRetired === false && hasLeftScheme === false) && ((scheme === "1995" && dateJoinedScheme < schemeDate2006 && !((status.special || status.mho) &&  dateJoinedScheme >= schemeDateStatus)) || (scheme === "2008" && dateJoinedScheme >= schemeDate2006))  && (isDateJoinedValid === true)) ? (
                                            isEligible === true ? (
                                                <Typography sx={{ mt: 2, mb: 1 }}>You are eligible for McCloud remedy, please continue</Typography>
                                            ) : (
                                                <Typography sx={{ mt: 2, mb: 1 }}>Based on your inputs, we believe you are not eligible for McCloud remedy. <br/> If you have any questions regarding eligibility, please contact your pension administrator </Typography>
                                            )
                                        ) : dateLeftScheme != null && dateLeftScheme < schemeDate2015 ? (
                                            <Typography sx={{ mt: 2, mb: 1 }}>Based on your inputs, we believe you are not eligible for McCloud remedy. <br/> If you have any questions regarding eligibility, please contact your pension administrator </Typography>
                                        ) : dateJoinedScheme < schemeDate2006 && dateOfBirth!== null && isRetired === false && hasLeftScheme === true && dateLeftScheme >= schemeDate2015 && isDateJoinedValid === true && (
                                            <Typography sx={{ mt: 2, mb: 1 }}>You are eligible for McCloud remedy, please continue</Typography>                      
                                        )} 

                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button variant="outlined"
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                            >
                                            Back
                                            </Button>
                                         <Box sx={{ flex: '1 1 auto' }} />

                                         {scheme === "1995" && isEligible === true && dateJoinedScheme != null && isRetired === false && hasLeftScheme === false && dateJoinedScheme < schemeDate2006 && isDateJoinedValid === true && !((status.special || status.mho) &&  dateJoinedScheme >= schemeDateStatus) ?  (
                                             <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNextEligible}>
                                             {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                             </Button>
                                         ) : scheme === "1995" && isEligible === true && dateJoinedScheme != null && dateJoinedScheme < schemeDate2006 && isRetired === false && hasLeftScheme === true && dateLeftScheme != null && dateLeftScheme >= schemeDate2015 && isDateJoinedValid === true && !((status.special || status.mho) &&  dateJoinedScheme >= schemeDateStatus) ? (
                                            <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNextEligible}>

                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                         ) : 
                                            scheme === "2008" && isEligible === true && dateJoinedScheme != null && isRetired === false && hasLeftScheme === false && dateJoinedScheme >= schemeDate2006 && isDateJoinedValid === true ?  (
                                            <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNextEligible}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>

                                        ) : scheme === "2008" && isEligible === true && dateJoinedScheme != null && dateJoinedScheme >= schemeDate2006 && isRetired === false && hasLeftScheme === true && dateLeftScheme != null && dateLeftScheme >= schemeDate2015 && isDateJoinedValid === true ? (
                                           <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNextEligible}>
                                           {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                           </Button>
                                         
                                         ): (
                                             <Button variant="outlined"
                                             style={{color:"rgb(198,198,198)"}}
                                             disabled
                                             onClick={handleNext}>
                                             {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                             </Button>
                                         )}

                                        


                                     </Box>
                                     </ThemeProvider>
                                    </LocalizationProvider>
                                </React.Fragment>
                            </div>


                        ) : activeStep === 2 ? (
                            
                            <div className="radio__container">
                                <React.Fragment>
                                
                                <Typography sx={{ mt: 2, mb: 1 }}>Tell us about your earnings</Typography>
                                <Typography sx={{ mt: 2, mb: 1 }}>If salary as at 1st April 2015 is unknown then leave this blank</Typography>
                                <Typography sx={{ mt: 2, mb: 1 }}>This should be pensionable earnings and should be the Full-Time Equivalent amount</Typography>
                                {/* {region === 'England & Wales' && <Typography sx={{ mt: 2, mb: 1 }}>Pensionable pay in the police currently includes basic salary, London weighting and additional salary on temporary promotion. It does not include the London Allowance.</Typography>} */}
                                {hasLeftScheme === true ? (
                                    <StyledTextField className="input__salary" 
                                    label="Salary as at Date Left Scheme (£)" 
                                    variant="standard" 
                                    style={{marginTop:"10px"}}
                                    value={currSalary} 
                                    onChange={event => handleSalaryInput(event, setCurrSalary)}
                                    />
                                ) : (
                                    <StyledTextField className="input__salary" 
                                    label="Current Salary (£)" 
                                    variant="standard" 
                                    style={{marginTop:"10px"}}
                                    value={currSalary} 
                                    onChange={event => handleSalaryInput(event, setCurrSalary)}
                                    />
                                )}
                                <StyledTextField className="input__salary" 
                                    label="Salary as at 1st April 2015 (£)" 
                                    variant="standard" 
                                    style={{marginTop:"10px", marginBottom:"10px"}} 
                                    value={prevSalary} 
                                    onChange={event => handleSalaryInput(event, setPrevSalary)}
                                    />
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button variant="outlined"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    >
                                    Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {(currSalary !== "") || (prevSalary !=="") ? (
                                        <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNextSalary}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    ) : (
                                        <Button variant="outlined"
                                        style={{color:"rgb(198,198,198)"}}
                                        disabled
                                        onClick={handleNextSalary}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    )} 
                                </Box>
                                </React.Fragment>
                            </div>




                        ) : activeStep === 3 ? (

                            <div className="radio__container">
                                <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>Tell us about any past periods in which you worked part-time hours, and any planned part-time periods in the future.</Typography>
                                <Typography sx={{ mt: 2, mb: 1 }}>If you have never worked part-time hours, and do not intend to in the future, then leave this blank.</Typography>
                                {partTime.map((period, index) => (
                                    <PartTimePeriod index={index} 
                                                    partTime={partTime} 
                                                    setPartTime={setPartTime} 
                                                    periodFrom={period.from}
                                                    periodTo={period.to} 
                                                    periodProportion={period.proportion} 
                                                    dateJoinedScheme={dateJoinedScheme} 
                                                    dateLeftScheme={dateLeftScheme}
                                                    validPartTimeFlag={validPartTimeFlag}
                                                    setValidPartTimeFlag={setValidPartTimeFlag}
                                                    fillPartTimeFlag={fillPartTimeFlag}
                                                    setFillPartTimeFlag={setFillPartTimeFlag}/>
                                    ))}

                                {partTimeOverlapWarning === true ? (
                                    <p>You can not have overlapping periods of part-time service</p>
                                ) : (
                                    null
                                )}
                                
                                <div className="addcircle__container" onClick={() => handleAddPartTimeField(partTime.length -1)}>
                                    <AddCircleIcon style={{fontSize:"30px"}}/>
                                    <p>Add row</p>  
                                </div>

                                {/* {fillPartTimeFlag === false ? (
                                    null
                                ) : (
                                    <p>Please fill in any empty boxes or correct any errors before adding another period of part-time service.</p>
                                )} */}

                                {fillPartTimeFlag === false && validPartTimeFlag === true ? (
                                    null
                                ) : (
                                    <div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>Please fill in any empty boxes or correct any errors before adding another period of part-time service or before clicking 'NEXT'</Typography>
                                        <Typography sx={{ mt: 2, mb: 1 }}>Note that any periods of part time service which are left blank or contain errors will be ignored.</Typography>
                                    </div>
                                    

                                )}
                             
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button variant="outlined"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    >
                                    Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                                </React.Fragment>
                            </div>
                        ) : (
                            <div className="radio__container">
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>What you told us</Typography>
                                    <TableContainer component={Paper} style={{marginTop:"20px"}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Your Details</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>                                               
                                            </TableHead>
                                            <TableBody>
                                                {/* <TableRow>
                                                    <TableCell>Employer</TableCell>
                                                    <TableCell>{employer}</TableCell>
                                                </TableRow> */}
                                                <TableRow>
                                                    <TableCell>Scheme</TableCell>
                                                    <TableCell>{scheme}</TableCell>
                                                </TableRow>
                                                {scheme === '1995' && statusName.includes('Special') && (
                                                <TableRow>
                                                    <TableCell>Special Status</TableCell>
                                                    <TableCell>Yes</TableCell>
                                                </TableRow>)}
                                                {scheme === '1995' && statusName.includes('MHO') && (
                                                <TableRow>
                                                    <TableCell>MHO Status</TableCell>
                                                    <TableCell>Yes</TableCell>
                                                </TableRow>)}

                                                {/* <TableRow>
                                                    <TableCell>Region</TableCell>
                                                    <TableCell>{region}</TableCell>
                                                </TableRow> */}
                                                <TableRow>
                                                    <TableCell>Date Of Birth</TableCell>
                                                    <TableCell>{moment(dateOfBirth).format("DD/MM/YYYY")}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Date Joined Scheme</TableCell>
                                                    <TableCell>{moment(dateJoinedScheme).format("DD/MM/YYYY")}</TableCell>
                                                </TableRow>
                                                {dateLeftScheme === null ? (
                                                <TableRow>
                                                    <TableCell>Date Left Scheme</TableCell>
                                                    <TableCell>N/A</TableCell>
                                                </TableRow>
                                                ) : (
                                                <TableRow>
                                                    <TableCell>Date Left Scheme</TableCell>
                                                    <TableCell>{moment(dateLeftScheme).format("DD/MM/YYYY")}</TableCell>
                                                </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell>Current Salary</TableCell>
                                                    <TableCell>£ {currSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                </TableRow>
                                                {prevSalary === null || prevSalary === '' ? (
                                                <TableRow>
                                                    <TableCell>Previous Salary</TableCell>
                                                    <TableCell>N/A</TableCell>
                                                </TableRow>
                                                ) : (
                                                <TableRow>
                                                    <TableCell>Previous Salary</TableCell>
                                                    <TableCell>£ {prevSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                </TableRow>
                                                )}
                                                {/* {region === 'England & Wales' && 
                                                <TableRow>
                                                    <TableCell>Metropolitan Police</TableCell>
                                                    <TableCell>{isMemberOfMet !== true ? ("No") : ("Yes")}</TableCell>

                                                </TableRow>} */}

                                                {/* <TableRow>
                                                    <TableCell>Rank</TableCell>
                                                    <TableCell>{rankAtRetirement}</TableCell>
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer component={Paper} style={{marginTop:"15px"}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Part-time Service</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Start Date</TableCell>
                                                    <TableCell>End Date</TableCell>
                                                    <TableCell>Percentage (%)</TableCell>
                                                </TableRow>
                                                {partTime.map((period, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{moment(period['from']).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell>{moment(period['to']).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell>{period['proportion']}</TableCell>                                                   
                                                </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Go back to make any changes or continue to Your Summary
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button variant="outlined"
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                            >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button variant="outlined" style={{color:colorTheme,border:colorTheme,borderStyle:'solid',borderWidth:'1px'}}  onClick={handleSubmit}>Continue</Button>
                                    </Box>
                                </React.Fragment>
                            </div>  
                        )}
                    </Box>
                </div>
            </div>
    )
}

export default AboutYou
