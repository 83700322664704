import { React, useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import "./TandCs.css"


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function TandCs( { titles, content }) {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };


    return (
        <div className="tandc__body">
        <div className="tandc__container">
            <h2 style={{marginBottom:'20px'}} >Terms and Conditions</h2>
            <Typography style={{marginTop:"10px"}}>By using this calculator you agree you have read and understood the Terms & Conditions set out below.</Typography>
            
            {titles.map( (titleDisplayed, i) => 
                <div key={i}>
                    <Accordion expanded={expanded === 'panel'+i } onChange={handleChange('panel'+i)} >
                        <AccordionSummary aria-controls={"panel"+i+"d-content"} id={"panel"+i+"d-header"}>
                            <Typography>{titleDisplayed}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            {content[i].map( (contentDisplayed, j) =>
                              <div class="tandc__div">
                                <Fragment key={j}>
                                    <span  style={{marginBottom:"10px",
                                                  fontWeight: contentDisplayed.includes('[header]') ? 'bold' : 'normal' }}>
                                          <div dangerouslySetInnerHTML={ {__html: contentDisplayed.replace('[header] ','')}} />
                                    </span>
                                </Fragment>
                                
                                <br></br>
                              </div>
                            )}
                        </Typography>
                            </AccordionDetails>
                    </Accordion>
                </div>
                )
            }

        </div>
    </div>

    )
}

export default TandCs
