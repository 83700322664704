import React from 'react';
import "./ComparisonChart.css"

import { useState } from 'react';
import {Line, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, BarChart, LineChart, ResponsiveContainer } from 'recharts';

import TooltipMui from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';

function ComparisonChart( { annualPension, lumpSum, viewOption, loading, titleInfoText, titles, lumpSumComparisonChart, transitionYear, scheme } ) {

    const determineLegendLabels = () => {

        let labels = []

        let titleOptions = ['Retirement at age 50 with 25 years of pensionable service', 
                            'Retirement at age 50 with 26 years of pensionable service', 
                            'Retirement at age 50 with 27 years of pensionable service', 
                            'Retirement at age 50 with 28 years of pensionable service', 
                            'Retirement at age 50 with 29 years of pensionable service', 
                            'Retirement at age 50 with 30 years of pensionable service', 
                            'Retirement at 20 years of service',
                            "Retirement at 25 years of pensionable service", 
                            "Retirement at 30 years of pensionable service", 
                            "Retirement at age 55", 
                            "Retirement at age 60", 
                            "Retirement at age 65", 
                            "Retirement at NPA", 
                            "Retirement at SPA",
                            "Leave scheme", 
                            "Immediate retirement"]

        let titleNames = [  "Age 50", 
                            "Age 50", 
                            "Age 50",
                            "Age 50", 
                            "Age 50", 
                            "Age 50", 
                            '20 years',
                            "25 years", 
                            "30 years",
                            "Age 55", 
                            "Age 60", 
                            "Age 65",
                            "NPA",
                            "SPA",
                            "Deferred", 
                            "Immediate"
        ]

        let colorOptions = ["rgb(6,3,141)",
                            "rgb(6,3,141)",
                            "rgb(6,3,141)",
                            "rgb(6,3,141)",
                            "rgb(6,3,141)",
                            "rgb(6,3,141)",
                            "rgba(237,139,0, 0.6)", 
                            "rgba(6,3,141, 0.6)", 
                            "rgb(237,139,0)", 
                            "rgb(165,165,165)", 
                            "rgb(138,27,87)", 
                            "rgb(6,3,141)",
                            "rgba(6,3,141, 0.6)", 
                            "rgb(237,139,0)", 
                            "rgb(0,99,91)", 
                            "rgb(75,149,96)"
        ]

        //"rgb(6,3,141, 0.8)", 

        

        for (let j = 0; j < titles.length; j++) {
            for (let i = 0; i < titleOptions.length; i++) {
            
                if (titleOptions[i].replace("pensionable ", "") === titles[j].replace("pensionable ", "") 
                    ) {
                    labels.push({name: titleNames[i], color: colorOptions[i], nameTooltip: titleOptions[i]})
                }
            }
        }

        return labels
    }

    const transitionYearSelector = 'Move to CARE in ' + transitionYear

    const legendLabels = determineLegendLabels()

    const [lineProps, setLineProps] = useState(
        legendLabels.reduce(
          (a, { name }) => {
            a[name] = false;
            return a;
          },
          { hover: null }
        )
      );

    const handleLegendMouseEnter = (e) => {
        if (!lineProps[e.value]) {
          setLineProps({ ...lineProps, hover: e.value });
        }
      };
    
    const handleLegendMouseLeave = (e) => {
        setLineProps({ ...lineProps, hover: null });
      };
    
    const selectLine = (e) => {
        setLineProps({
          ...lineProps,
          [e.value]: !lineProps[e.value],
          hover: null
        });
      };


    const CustomTooltipPensionValues = ({ active, payload, label }) => {
        //console.log({'label':label,'scheme':scheme})
        if (loading === false) {
            if (active && annualPension[0] !== null && annualPension[1] !== null && annualPension[2] !== null) {
                return (
                    <div className="customTooltip">
                        {parseInt(label) >= (scheme === '1987' ? 45 : 50) && <p>Age : {`${label}`}</p>}
                        {legendLabels.map((legendLabel, index) => (
                            parseInt(label) >= (scheme === '1987' ? 45 : 50) && annualPension[viewOption][parseInt(label)-(scheme === '1987' ? 45 : 50)] !== null && (
                                viewOption !== 2 ? (
                                    <p key={index} style={{ color:legendLabel['color']}}>{legendLabel['nameTooltip']}: {'£' + Math.round(annualPension[viewOption][index][parseInt(label)-(scheme === '1987' ? 45 : 50)][transitionYearSelector]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </p>
                                ) : (
                                    <p key={index} style={{  color:legendLabel['color']  }}> {legendLabel['nameTooltip']}: {Math.round(annualPension[viewOption][index][parseInt(label)-(scheme === '1987' ? 45 : 50)][transitionYearSelector]*100) + '%'}</p>
                                )
                            )
                        ))}
                    </div>
                )
            } else {
                return null
            }
        } else {
            return null
        }

    }

    const CustomTooltipLumpsumValues = ({ active, payload, label }) => {

        if (loading === false) { 
            if (active && lumpSum[0] !== null && lumpSum[1] !== null && lumpSum[2] !== null) {
                // console.log({'label': label, 'active': active, 'viewOption': viewOption})
                // console.log({'lumpSum': lumpSum})
                let labelMapping = []
                for (let i = 0; i < lumpSum[viewOption].length; i++) {
                    let labelMappingEntry = {}
                    for (let j = 0; j < lumpSum[viewOption][i].length ; j++) {
                        labelMappingEntry[lumpSum[viewOption][i][j]['Age']] = j
                    }
                    labelMapping.push(labelMappingEntry)
                }

                // console.log({'labelMagpping':labelMapping,
                //             'legendLabels': legendLabels})

                return (
                    <div className="customTooltip">
                        <p>Age : {`${label}`}</p>
                        {legendLabels.map((legendLabel, index) => (
                            lumpSum[viewOption][0] !== null && (
                                viewOption !== 2 ? (
                                    lumpSum[viewOption][index][labelMapping[index][label]] !== undefined ? (
                                        <p key={index} style={{  color:legendLabel['color']  }}> {legendLabel['nameTooltip']}: {'£' + Math.round(lumpSum[viewOption][index][labelMapping[index][label]][transitionYearSelector]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } </p>
                                    ) : (
                                        <p key={index} style={{  color:legendLabel['color']  }}> {legendLabel['nameTooltip']}: {'£0' } </p>
                                    )

                                ) : (
                                    lumpSum[viewOption][index][labelMapping[index][label]] !== undefined ? (
                                        <p key={index} style={{  color:legendLabel['color']  }}> {legendLabel['nameTooltip']}: {Math.round(lumpSum[viewOption][index][labelMapping[index][label]][transitionYearSelector]*100) + '%' } </p>
                                    ) : (
                                        <p key={index} style={{  color:legendLabel['color']  }}> {legendLabel['nameTooltip']}: {'0%' } </p>
                                    )
                                )

                            )
    
                        ))}
                    </div>
                )
            } else {
                return null
            }
        } else {
            return null
        }

    }

    const click = () => {
        // console.log(lumpSumComparisonChart[viewOption])
        // console.log(titles)
        // console.log(titles, titlesReversed)
    }
    
    const maxComparisionPension = Math.ceil(annualPension[viewOption][annualPension[viewOption].length-2][annualPension[viewOption][annualPension[viewOption].length-2].length -1][transitionYearSelector])
    
    const pensionTickArray = [0, 
        Math.ceil(maxComparisionPension/10000)*10000/4, 
        Math.ceil(maxComparisionPension/10000)*10000/2, 
        Math.ceil(maxComparisionPension/10000)*10000*3/4, 
        Math.ceil(maxComparisionPension/10000)*10000]

    const maxComparisonLumpSum = () => {
        const lumpSumData = lumpSumComparisonChart[transitionYear][viewOption]
        let maxValue = 0
        for (var i = 0; i < lumpSumData.length ; i++) {
            let currValue = 0
            for (const [key, value] of Object.entries(lumpSumData[i])) {
                if (key !== 'Age') {
                    currValue = value
                }
            }
            maxValue = Math.max(maxValue, currValue)
        }
        return maxValue
    }

    const lumpSumTickArray = [0, 
                                Math.ceil(Math.max(maxComparisonLumpSum(),maxComparisionPension)/10000)*10000/4, 
                                Math.ceil(Math.max(maxComparisonLumpSum(),maxComparisionPension)/10000)*10000/2, 
                                Math.ceil(Math.max(maxComparisonLumpSum(),maxComparisionPension)/10000)*10000*3/4, 
                                Math.ceil(Math.max(maxComparisonLumpSum(),maxComparisionPension)/10000)*10000]

    //console.log(annualPension[viewOption])
    
    return (
        <div className="carouselpanel__container" onClick={click}>

            <div className="carouselpanel__title" style={{marginBottom:"10px"}}>
                <h3>Comparison Chart</h3>
                <TooltipMui title={<Typography fontSize={'16px'}>{titleInfoText}</Typography>}>
                    <IconButton id="carouselpanel__title__infobutton" >
                        <InfoIcon id="carouselpanel__title__infobutton" />
                    </IconButton>
                </TooltipMui>
            </div>

            <Typography style={{textAlign:"center"}}>The pension and lump sum amount for each retirement age shown in the chart reflect the cash lump sums chosen on the individual tabs that follow (where the default option is to take the maximum lump sum available).</Typography>
            
            {scheme !== "2006" && <Typography style={{textAlign:"center"}}>You also have the option to choose when you receive your 2015 Scheme benefits for retirement below age 55 (the default is age 55 with actuarial reduction). The "Deferred retirement" scenario is based on the age you choose to leave the scheme (the default is your current age). </Typography>}

            <Typography sx={{ mt: 2, mb: 1 }}>Hovering over results in the chart will display details of the figures being illustrated</Typography>

            <div className="carouselpanel__graphs__container">
                <ResponsiveContainer width="48%" height={400}>
                    <LineChart>
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1"s/>                               
                        <XAxis 
                            xAxisId={0}
                            dataKey="Age" 
                            label= {{ value: "Age", dy:10}} 
                            type="number" 
                            domain={scheme === '1987' ? [45, 70] : [50,70] }
                            height={60}
                            ticks={scheme === '1987' ? [ 45, 50, 55, 60, 65, 70] : [ 50, 55, 60, 65, 70] }
                        />
                        {viewOption === 2 ? (
                        <YAxis 
                            label= {{ value: "Annual Pension (%)", angle: -90, position: 'insideLeft', dy: 50}} 
                            tickFormatter={(tick) => { return (`${tick*100}`)}} 
                            width={80}
                            yAxisId={0}
                            tickCount={5}
                        />
                        ) : (
                            annualPension[viewOption] == null ? (
                            <YAxis 
                                label={{ value: "Annual Pension (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                yAxisId={0}
                                tickCount={5}
                            />
                            ) : (
                            <YAxis 
                                label={{ value: "Annual Pension (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                yAxisId={0}
                                interval='preserveEnd'
                                ticks={pensionTickArray}
                                domain={[0, Math.ceil(maxComparisionPension*10000)/10000 ]}
                            />
                            )

                        )}
                        {annualPension[viewOption] != null && (
                            <Tooltip content={<CustomTooltipPensionValues />} />
                        )}
                        <Legend 
                            wrapperStyle={{cursor:"pointer"}}
                            layout="vertical"
                            onClick={selectLine} 
                            onMouseOver={handleLegendMouseEnter} 
                            onMouseOut={handleLegendMouseLeave}
                            iconType={'plainline'} 
                        />

                        {titles.map((title, index) => (
                            index === titles.length - 1 || index === 0 ? (
                                null
                            ) : (
                            <Line
                                data={annualPension[viewOption][index]}
                                dataKey={transitionYearSelector}
                                key={index}
                                name={legendLabels[index]['name']}
                                strokeWidth={2}
                                stroke={legendLabels[index]['color']}
                                hide={lineProps[legendLabels[index]['name']] === true}
                                yAxisId={0}
                                xAxisId={0}
                                animationDuration={0}
                                type="stepAfter"
                                dot={false}
                            />
                            )
                        ))}
                        <Line
                            data={annualPension[viewOption][0]}
                            dataKey={transitionYearSelector}
                            key={0}
                            name={legendLabels[0]['name']}
                            strokeWidth={2}
                            stroke={legendLabels[0]['color']}
                            hide={lineProps[legendLabels[0]['name']] === true}
                            yAxisId={0}
                            xAxisId={0}
                            animationDuration={0}
                            type="stepAfter"
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>

                <TooltipMui title={<Typography fontSize={'16px'}>Click on the names in the legend to hide or show each option.</Typography>}>
                    <IconButton id="carouselpanel__legend__infobutton" >
                        <InfoIcon id="carouselpanel__legend__infobutton" />
                    </IconButton>
                </TooltipMui>

                <ResponsiveContainer width="48%" height={400}>
                    <BarChart classname="bar__graph" data={lumpSumComparisonChart[transitionYear][viewOption]}>
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                        {viewOption === 2 ? (
                        <YAxis 
                            label= {{ value: "Lump Sum (%)", angle: -90, position: 'insideLeft', dy: 50}} 
                            tickFormatter={(tick) => { return (`${tick*100}`)}} 
                            width={80}
                        />
                        ) : (
                            annualPension[viewOption] == null ? (
                            <YAxis 
                                label={{ value: "Lump Sum (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                tickCount={5}
                            />
                            ) : (
                            <YAxis 
                                label={{ value: "Lump Sum (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}     
                                interval='preserveEnd'
                                ticks={lumpSumTickArray}
                                domain={[0, Math.ceil(maxComparisonLumpSum()/10000)*10000 ]}
                            />
                            )

                        )}
                        {annualPension[viewOption] != null && (
                            <Tooltip content={<CustomTooltipLumpsumValues />} />
                        )}
                        <Legend 
                            wrapperStyle={{cursor:"pointer", marginLeft:"15%"}} 
                            onClick={selectLine} 
                            onMouseOver={handleLegendMouseEnter} 
                            onMouseOut={handleLegendMouseLeave} 
                        />
                        {titles.map((title, index) => (
                            index === titles.length - 1 || index === 0 ? (
                                null
                            ) : (
                            <React.Fragment key={index}>
                            <XAxis 
                                dataKey="Age" 
                                label= {{ value: "Age", dy: 10}} 
                                type="number" 
                                height={60}                                
                                xAxisId={index}
                                domain={scheme === '1987' ? [45, 70] : [50,70] }
                                ticks={scheme === '1987' ? [ 45, 50, 55, 60, 65, 70] : [ 50, 55, 60, 65, 70] }
                                hide={index !== 1 ? true : false}
                            />
                            <Bar
                                xAxisId={index}
                                fill={legendLabels[index]['color']}
                                fillOpacity={1}
                                barSize={20}
                                dataKey={title}
                                hide={lineProps[legendLabels[index]['name']] === true}
                                name={legendLabels[index]['name']}
                                animationDuration={0}
                            />
                            </React.Fragment>
                            )
                        ))}
                        <XAxis 
                            dataKey="Age" 
                            label= {{ value: "Age", dy: 10}} 
                            type="number" 
                            height={60}
                            xAxisId={0}
                            domain={scheme === '1987' ? [45, 70] : [50,70] }
                            ticks={scheme === '1987' ? [ 45, 50, 55, 60, 65, 70] : [ 50, 55, 60, 65, 70] }
                            hide
                        />
                        <Bar
                            xAxisId={0}
                            fill={legendLabels[0]['color']}
                            fillOpacity={1}
                            barSize={20}
                            dataKey={titles[0]}
                            hide={lineProps[legendLabels[0]['name']] === true}
                            name={legendLabels[0]['name']}
                            animationDuration={0}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>

        </div>
    )
}

export default ComparisonChart
